import React from 'react'
import { Link } from 'gatsby'
import * as images from 'images/home'
import Helmet from 'react-helmet'
import { heroKlantenservice } from 'images/hulp/klantenservice'
import Layout from '../../layouts'

import Checkmark from 'components/misc/checkmark'
const {
  logos: { ...logos }
} = images

class DemoPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if(id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="demo animated fadeInPage">

          <Helmet>
            <title>Plan een telefonische demo in | Online salarisadministratie | Employes</title>
            <meta name="description" content="Vraag een demo aan. Daarna neemt één van onze salarisexperts contact op voor een telefonische uitleg van Employes." />
            <meta itemprop="name" content="Plan een telefonische demo in | Online salarisadministratie | Employes" />
            <meta itemprop="description" content="Vraag een demo aan. Daarna neemt één van onze salarisexperts contact op voor een telefonische uitleg van Employes." />
            <meta itemprop="image" content="/static/meta-img.png" />
            <meta name="robots" content="noindex,follow" />
          </Helmet>

          <header className="header padding-xl">
            <div className="container-md relative">
              <div className="grid yg align-middle">
                <div className="col-5">
                  <div className="title margin-s-bottom">
                    <h5 className="eyebrow turq no-margin">Bevestiging</h5>
                    <h2>We hebben je aanvraag ontvangen.</h2>
                    <p>We nemen zo snel mogelijk contact met je op een afspraak in te plannen.</p>
                  </div>
                </div>
                <div className="hero-illustration">
                  <img src={heroKlantenservice} />
                </div>
              </div>
            </div>
            <div className="logo-grid padding-xl-top hide-mobile block">
              <div className="container-sm margin-xs-bottom">
                <div className="grid yg align-middle logos">
                  {Object.keys(logos).map((img, idx) => (
                    <div className="col-3 align-middle no-mar" key={idx}>
                      <div className={`logo ${idx === 1 ? 'petossi' : ''}`}>
                        <img src={logos[img]} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="container-sm">
                <div className="grid center text-center yg align-middle">
                  <p className="hide-mobile">We helpen meer dan 300+ bedrijven en 1500+ werknemers</p>
                </div>
              </div>
            </div>
          </header>


        </main>
      </Layout>
    )
  }
}

export default DemoPage
